import { defineRule } from 'vee-validate';
import {
    required,
    email,
    min,
    max,
    numeric,
    alpha,
    regex,
    min_value,
    max_value,
    integer
} from '@vee-validate/rules';
import { dateIsBetween as dateIsBetweenFn } from '~/utils';
import type { DateIsBetweenArgs } from '~/types';
import { isEmpty as lo_isEmpty } from 'es-toolkit/compat';
import { isNil as lo_isNil } from 'es-toolkit';
import { isNumber as lo_isNumber } from 'es-toolkit/compat';
import { isString as lo_isString } from 'es-toolkit';


// Password validation rules
export function passwordMustNotBeSameAsLast(val: string, [fieldValue]: [unknown]): string | boolean {
    return val !== fieldValue ? true : 'New password must not match old password.';
}

export function passwordsMustMatch(val: string, [fieldValue]: [unknown]): string | boolean {
    return val === fieldValue ? true : 'Passwords do not match.';
}

// Required
export const msgErrorForRequired = 'This field is required';
export function isRequired(val: unknown) {
    const test = (!Number.isNaN(val) && lo_isNumber(val)) || (!lo_isNil(val) && !lo_isEmpty(val));
    return test ?  true : msgErrorForRequired;
}

export function maxLength(max: number = 0, val: unknown) {
    const test = (lo_isString(val) && val.length <= max);
    return test ?  true : `Max ${max} characters allowed`;
}

export function dateIsBetween(value: Date, args: DateIsBetweenArgs): boolean {
    return dateIsBetweenFn(value, args);
}


export function notEmpty(value: Array<unknown>| object): boolean {
    return !lo_isEmpty(value);
}

export const UsernameValidationRules: Record<string, unknown> = {
    required: true,
    email: true
};

export const PasswordValidationRules: Record<string, unknown> = {
    required: true,
    min: 8,
    regex: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/
};

export const BirthdateValidationRules: Record<string, unknown> = {
    regex: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
};

const rulesMap: Record<string, unknown> = {
    required: required,
    email: email,
    numeric: numeric,
    max: max,
    min: min,
    max_value: max_value,
    min_value: min_value,
    alpha: alpha,
    regex: regex,
    integer: integer,
    date_is_between: dateIsBetween,
    not_empty: notEmpty,
    password_match: passwordsMustMatch,
    password_not_be_same_as_last: passwordMustNotBeSameAsLast
};

export function initVeeRules(rules: (keyof typeof rulesMap)[] = []): void {
    // rules.forEach((rule) => {
    //     defineRule(rule, rulesMap[rule] as any);
    // });

    // NOTE: This is a workaround for the above code. The above code is not working in production build.
    const keys = Object.keys(rulesMap);
    keys.forEach((rule) => {
        defineRule(rule, rulesMap[rule] as any);
    });
}
